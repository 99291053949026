import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parseQty from '../lib/ingredientQuantityParser'
import SettingsContext from '../context/SettingsContext'
import SectionTitle from './styles/SectionTitle'
import Video from './Video'
import { mediaToggled } from '../lib/segmentEvents'

const TasksContainer = styled.div`
  grid-area: ${props => props.gridArea};
`

const getTitle = (taskType, hasMultipleSteps) => {
  switch (taskType) {
    case 'PREP':
      return 'Prep'
    case 'COOK':
      return hasMultipleSteps ? 'Cooking Steps' : 'Cooking Step'
    case 'FINISH':
      return hasMultipleSteps ? 'Finishing Touches' : 'Finishing Touch'
    default:
      break
  }
}

const RecipeTasks = ({ tasksByStep, gridArea }) => {
  return (
    // Render a step section for each step type ("Prep", "Cooking steps", "Finishing touches")
    <TasksContainer className="recipe-tasks" gridArea={gridArea}>
      {Object.keys(tasksByStep).map((step, i, arr) => {
        const title = getTitle(step, tasksByStep[step].length > 1)
        return <StepSection title={title} tasks={tasksByStep[step]} key={i} />
      })}
    </TasksContainer>
  )
}

const StepSectionContainer = styled.div`
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 80px;
  }
`

const StepSection = ({ title, tasks }) => {
  const [isOpen, setOpen] = useState(true)
  // quick fix for trailing 'undefined' in task arrays
  tasks = tasks.filter(
    task => typeof task === 'object' && task.hasOwnProperty('title')
  )
  return (
    <StepSectionContainer>
      <SectionTitle>
        <h3 className="title">{title}</h3>
        <div className="toggle expand">
          <span
            onClick={() => {
              mediaToggled(title, isOpen ? 'hide' : 'show')
              setOpen(!isOpen)
            }}
          >
            {isOpen ? 'Hide Videos' : 'Show Videos'}
          </span>
        </div>
      </SectionTitle>
      <div>
        {/* Render a Task Area for each task */}
        {tasks.map((task, i) => {
          const num =
            tasks.length > 1 && task.taskStep !== 'PREP' ? i + 1 : null
          return (
            <Task data={task} num={num} key={task.id + i} isOpen={isOpen} />
          )
        })}
      </div>
    </StepSectionContainer>
  )
}

const TaskContainer = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 0px;
  p,
  ul,
  .media {
    > video,
    > img {
      width: 100%;
    }
  }
  .media {
    display: ${props => (props.isOpen ? 'static' : 'none')};
  }

  > h4 {
    color: #404040;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  .prompt, .prompt p {
    margin-top: 8px;
    margin-bottom: 12px;
    color: #404040;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
  > ul.ingredient-card {
    background: rgba(241, 241, 241, 0.5);
    border-radius: 4px;
    margin-top: 12px;
    padding: 20px 24px;
    > li {
      color: #404040;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0px;
      }
      > span {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        &.ing-with-treatment span {
          display: block;
          &.treatment {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
          }
        }
      }
    }
  }
  > div.media {
    margin-top: 16px;
  }
`

const Task = ({ data, num, isOpen }) => {
  const { units } = useContext(SettingsContext)

  const {
    title,
    prompt,
    ingredients,
    mediaType,
    mediaUrl,
    mediaRepeat,
    mediaAutoplay,
    temperatureInC,
    temperatureInF,
    taskStep,
  } = data

  const isPrep = taskStep === 'PREP' // No numbers in front of prep steps
  const media =
    mediaType && mediaUrl ? (
      mediaType === 'video/mp4' ? (
        <Video src={mediaUrl.replace('http:', 'https:')} />
      ) : (
        <img src={mediaUrl.replace('http:', 'https:')} />
      )
    ) : null
  return (
    <TaskContainer isOpen={isOpen}>
      <h4>{num ? `${num}. ${title}` : title}</h4>
      {prompt ? prompt.childMarkdownRemark.html
      ? <div className="prompt" dangerouslySetInnerHTML={{ __html: prompt.childMarkdownRemark.html }} />
      : <div className="prompt"><p>{prompt.prompt}</p></div>
      : null}
      {ingredients && (
        <ul className="ingredient-card">
          {ingredients.map((ingredient, i) => {
            let qtyAndUnitsHtml = parseQty(ingredient, true)
            if (qtyAndUnitsHtml && qtyAndUnitsHtml.standard)
              qtyAndUnitsHtml = qtyAndUnitsHtml[units]
            return (
              <li key={i + ingredient.name['en_US']}>
                {ingredient.treatment ? (
                  <span className="ing-with-treatment">
                    <span>{ingredient.name['en_US']}</span>
                    <span className="treatment">
                      ({ingredient.treatment['en_US']})
                    </span>
                  </span>
                ) : (
                  <span>{ingredient.name['en_US']}</span>
                )}

                {qtyAndUnitsHtml && <span dangerouslySetInnerHTML={{ __html: qtyAndUnitsHtml }} />}
              </li>
            )
          })}
        </ul>
      )}
      {media && <div className="media">{media}</div>}
    </TaskContainer>
  )
}

export default RecipeTasks
