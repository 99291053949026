import React, { useState } from 'react'
import styled from 'styled-components'
import jsonp from 'jsonp'

const EmailForm = styled.div`
  background-color: ${({ area }) =>
    area === 'aside' && 'rgba(254, 213, 161, 0.5)'};
  border-radius: 6px;
  margin-top: 48px;
  padding: 24px 20px 32px 20px;
  text-align: center;
  > .signup-text {
    .signup-text-title {
      color: #1a1a1a;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 8px;
    }
    .signup-text-desc {
      color: #404040;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }
  > .signup-form {
    display: ${props => (props.status === 'success' ? 'none' : 'flex')};
    background-color: #ffffff;
    border: 1px solid
      ${props => (props.status === 'error' ? '#F4001E' : '#cbcfd1')};
    border-radius: 36px;
    flex-flow: row nowrap;
    height: 40px;
    width: 100%;
    &:focus-within {
      outline: 5px auto rgba(0, 150, 255, 1);
    }
    .signup-form-input {
      background: #fff;
      border: none;
      border-radius: 36px;
      color: #757575;
      flex: 1;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      margin: 1px;
      padding-left: 24px;
      &:focus {
        /* Outline removed here and placed on parent form */
        outline: none;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: #757575 !important;
      }
    }
    .signup-form-submit {
      border: none;
      border-radius: 36px;
      background-color: #f47521;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 14px;
      line-height: 18px;
      margin: 3px 3px 3px 0px;
      outline: none;
      padding: 7px 16px;
      -webkit-appearance: none;
    }
  }
  > .response {
    display: ${props => (props.status === 'success' ? 'none' : 'block')};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin: 4px 0px 0px 24px;
    text-align: left;
    /* margin-top: -1px; */
    /* position: absolute; */
    &.error {
      color: #f4001e;
    }
    &.sending {
      color: #404040;
    }
  }

  @media (max-width: 1024px) {
    display: ${props => (props.area === 'aside' ? 'none' : 'flex')};
  }

  ${props =>
    props.area === 'bottom' &&
    `
    grid-area: ${props.gridArea};
    background: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding: 28px 0px;
    display: flex;
    position: relative;
    z-index: 0;
    &:before {
      content: "";
      /* height: 100%; */
      top: 0;
      bottom: 0;
      position: absolute;
      width: 100vw;
      background: rgba(254, 213, 161, 0.5);
      /* left: calc(-100vw + (1120px + ((100vw - 1120px) / 2))); */
      /* right: calc(-100vw + (1120px + ((100vw - 1120px) / 2))); */
      z-index: -1;
    }
    > .signup-text {
      flex: 7;
      margin-right: 20px;
      text-align: left;
      text-align: ${props.status === 'success' ? 'center' : 'left'};
      .signup-text-title {
        margin-bottom: 4px;
      }
      .signup-text-desc {
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 0px;
      }
    }
    > .signup-form {
      align-items: center;
      flex: 5;
      margin-left: 20px;
    }
    > .response {
    display: ${props.status === 'success' ? 'none' : 'block'};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin: 40px 0px 0px 105px;
    left: 50%;
    text-align: left;
    /* margin-top: -1px; */
    position: absolute;
    &.error {
      color: #f4001e;
    }
    &.sending {
      color: #404040;
    }
  }
  @media (max-width: 1024px) {
    > .signup-text {
      flex: 1;
    }
    > .signup-form {
      flex: 1;
    }
    > .response {
      margin: 40px 0px 0px 20px;
    }
  }
  @media (max-width: 768px) {
      background: rgba(254, 213, 161, 0.5);
      padding: 28px 20px;
      &:before {
        display: none;
      }
      flex-flow: column nowrap;
      > .signup-text {
        margin-bottom: 20px;
        text-align: center;
      }
      > .signup-form {
        margin-left: 0px;
      }
      > .response {
        position: static;
        left: auto;
        /* margin: 0 ; */
        margin: 4px 0px -20px 0px;
        width: 100%;
      }
    }
    `}
`

const EmailCapture = ({ area, gridArea }) => {
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState(null)
  const [email, setEmail] = useState('')

  const subscribe = email => {
    const urlObj = new URL(
      'https://hestancue.us3.list-manage.com/subscribe/post?u=9cc5744f8b1629977a15fc69a&amp;id=a6a5e833a9'
    )
    urlObj.searchParams.append('EMAIL', email)
    const url = urlObj.href.replace('/post?', '/post-json?')
    const opts = { param: 'c' }

    setStatus('sending')

    jsonp(url, opts, (err, data) => {
      if (err) {
        setStatus('error')
        setMessage(err)
      } else if (data.result !== 'success') {
        setStatus('error')
        setMessage(data.msg)
      } else {
        setStatus('success')
        setMessage(data.msg)
      }
    })
  }

  const submit = e => {
    e.preventDefault()
    return email && email.indexOf('@') > -1 && subscribe(email)
  }

  const title =
    status === 'success' ? 'Thank you for signing up!' : 'Let’s Get Cooking!'
  const description =
    status === 'success'
      ? `Almost done! We sent you an email verify you own this address. \n\nPsst…Have you tried the Buttermilk Pancakes yet?`
      : `Get the latest recipes, tips and tricks from the Cue test kitchen!`

  return (
    <EmailForm status={status} area={area} gridArea={gridArea}>
      <div className="signup-text">
        <h3 className="signup-text-title">{title}</h3>
        <p className="signup-text-desc">
          {/* To inlcude line breaks for multiple sentences */}
          {description.split('\n').map(function(item, key) {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            )
          })}
        </p>
      </div>
      <MailchimpForm
        submit={submit}
        email={email}
        status={status}
        message={message}
        setEmail={setEmail}
        errorMsg="There was an error with that email address. Please try another."
      />
    </EmailForm>
  )
}

export const MailchimpForm = ({
  submit,
  email,
  status,
  message,
  setEmail,
  errorMsg,
  successMsg,
}) => (
  <>
    <form onSubmit={submit} className="signup-form">
      <input
        type="email"
        name="EMAIL"
        id="mce-EMAIL"
        placeholder="Your email"
        onChange={e => setEmail(e.target.value)}
        value={email}
        className="signup-form-input"
      />
      <input type="submit" value="Sign Up" className="signup-form-submit" />
    </form>
    {status === 'sending' && <div className="response sending">Sending...</div>}
    {status === 'error' && (
      <div
        className="response error"
        dangerouslySetInnerHTML={{ __html: errorMsg ? errorMsg : message }}
      ></div>
    )}
    {status === 'success' && (
      <div
        className="response success"
        dangerouslySetInnerHTML={{ __html: successMsg ? successMsg : message }}
      />
    )}
  </>
)

export default EmailCapture
