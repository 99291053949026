import React, { useContext } from 'react'
import styled from 'styled-components'
import SettingsContext from '../context/SettingsContext'
import parseQty from '../lib/ingredientQuantityParser'
import { mediaMaxWidth } from './styles/media'
import { unitsSwitched } from '../lib/segmentEvents'

import SectionTitle from './styles/SectionTitle'

const IngredientsContainer = styled.div`
  margin-bottom: 60px;

  .standard {
    text-decoration: ${props =>
      props.units === 'standard' ? 'underline' : 'none'};
  }
  .metric {
    text-decoration: ${props =>
      props.units === 'metric' ? 'underline' : 'none'};
  }
  div.ingredient-list {
    margin-bottom: 24px;
    margin-top: 16px;
    padding: 0;
    &:last-child {
      margin-bottom: 0px;
    }
    > h4 {
      color: #404040;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 12px;
    }
    > ul {
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      > li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 12px;
        .ing-with-treatment span {
          display: block;
          &.treatment {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
        span.quantity {
          white-space: nowrap;
        }
      }
    }
  }
  ${mediaMaxWidth('tablet')`
    flex: 1;
    margin-bottom: 0;
    margin-right: 20px;
  `}
  ${mediaMaxWidth('mobile')`
    margin-bottom: 32px;
    margin-right: 0;
  `}
`

const RecipeIngredients = ({ ingredientsByHeader, gridArea }) => {
  const { units, setUnits } = useContext(SettingsContext)
  return (
    <IngredientsContainer
      className="recipe-ingredients"
      gridArea={gridArea}
      units={units}
    >
      <SectionTitle>
        <h3 className="title">Ingredients</h3>
        <div className="toggle">
          <span
            onClick={() => {
              unitsSwitched('standard')
              setUnits('standard')
            }}
            className="standard"
          >
            Standard
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span
            onClick={() => {
              unitsSwitched('metric')
              setUnits('metric')
            }}
            className="metric"
          >
            Metric
          </span>
        </div>
      </SectionTitle>

      {Object.entries(ingredientsByHeader).map(([header, ingredients], i) => {
        return (
          <div className="ingredient-list" key={i}>
            {header !== 'null' && <h4>{header}</h4>}

            <ul>
              {ingredients.map((ingredient, j) => {
                const {
                  name,
                  treatment,
                  ordinalQuantity,
                  metricQuantity,
                  metricUnit,
                  standardUnit,
                  standardQuantity,
                } = ingredient

                let qtyAndUnitsHtml = parseQty(ingredient)
                if (qtyAndUnitsHtml && qtyAndUnitsHtml.standard) {
                  qtyAndUnitsHtml = qtyAndUnitsHtml[units]
                }
                return (
                  <li key={j}>
                    {treatment ? (
                      <span className="ing-with-treatment">
                        <span>{name}</span>
                        <span className="treatment">
                          ({treatment.replace('(', '').replace(')', '')})
                        </span>
                      </span>
                    ) : (
                      <span>{name}</span>
                    )}

                    {qtyAndUnitsHtml && <span
                      className="quantity"
                      dangerouslySetInnerHTML={{ __html: qtyAndUnitsHtml }}
                    />}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </IngredientsContainer>
  )
}

export default RecipeIngredients
