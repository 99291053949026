import React from 'react'
import { recipeShared } from '../lib/segmentEvents'

const SocialShareIcon = ({ type, url, shareText, mediaUrl, subject }) => {
  const encodeURI = string => {
    if (typeof string === 'undefined') {
      return ''
    }
    return encodeURIComponent(string)
  }

  const { fullUrl, socialIcon } = (type => {
    switch (type) {
      case 'facebook': {
        return {
          fullUrl: `https://facebook.com/sharer/sharer.php?u=${encodeURI(url)}`,
          socialIcon: FacebookCircle(),
        }
      }
      case 'twitter': {
        return {
          fullUrl: `https://twitter.com/intent/tweet/?text=${encodeURI(
            shareText
          )}&url=${encodeURI(url)}`,
          socialIcon: TwitterCircle(),
        }
      }
      case 'pinterest': {
        return {
          fullUrl: `https://pinterest.com/pin/create/button/?url=${url}&media=${encodeURI(
            mediaUrl
          )}&description=${shareText}`,
          socialIcon: PinterestCircle(),
        }
      }
      case 'email': {
        return {
          fullUrl: `mailto:?subject=${encodeURI(subject)}&body=${shareText}`,
          socialIcon: EmailCircle(),
        }
      }
      default:
        return
    }
  })(type)

  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=100,top=100`

  return (
    <a
      className={`social-icon ${type}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        recipeShared(type)
        window.open(fullUrl, 'Share Hestan Cue Recipe', params)
      }}
    >
      {socialIcon}
    </a>
  )
}

export default SocialShareIcon

const FacebookCircle = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Facebook Circle</title>
    <defs>
      <path
        d="M0,16 C0,24.8365614 7.1634386,32 16,32 L16,32 C24.8365614,32 32,24.8365614 32,16 L32,16 C32,7.1634386 24.8365614,0 16,0 L16,0 C7.1634386,0 0,7.1634386 0,16 L0,16 Z"
        id="path-1"
      ></path>
      <path
        d="M13.9559028,11.8449042 L13.9559028,13.9900208 L11,13.9900208 L11,16.8987917 L13.9559028,16.8987917 L13.9559028,24.3625 L17.4909028,24.3625 L17.4909028,16.8987917 L20.4392361,16.8987917 L20.880625,13.9900208 L17.4909028,13.9900208 L17.4909028,12.1329833 C17.4909028,11.2908458 17.7660417,10.7168833 19.1872917,10.7168833 L19.1872917,10.7168833 L21,10.7162458 L21,8.11467917 C20.6865278,8.07919167 19.6104861,8 18.3586111,8 L18.3586111,8 C15.7451389,8 13.9559028,9.35560833 13.9559028,11.8449042 L13.9559028,11.8449042 Z"
        id="path-3"
      ></path>
    </defs>
    <g
      id="112019---Share-Updates-After-Meeting"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SocialShare_Desktop_1280"
        transform="translate(-757.000000, -408.000000)"
      >
        <g id="RECIPE_OVERVIEW" transform="translate(12.000000, 124.000000)">
          <g
            id="RecipeOverview_Text"
            transform="translate(745.000000, 20.000000)"
          >
            <g id="SocialIcons" transform="translate(0.000000, 264.000000)">
              <g id="Facebook_Cirlce">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="CircleBG" fill="#3C5A9A" xlinkHref="#path-1"></use>
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <use id="FacebookIcon" fill="#FFFFFF" xlinkHref="#path-3"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const TwitterCircle = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Twitter Circle</title>
    <defs>
      <path
        d="M0,16 C0,24.8365614 7.1634386,32 16,32 L16,32 C24.8365614,32 32,24.8365614 32,16 L32,16 C32,7.1634386 24.8365614,0 16,0 L16,0 C7.1634386,0 0,7.1634386 0,16 L0,16 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="112019---Share-Updates-After-Meeting"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SocialShare_Desktop_1280"
        transform="translate(-805.000000, -408.000000)"
      >
        <g id="RECIPE_OVERVIEW" transform="translate(12.000000, 124.000000)">
          <g
            id="RecipeOverview_Text"
            transform="translate(745.000000, 20.000000)"
          >
            <g id="SocialIcons" transform="translate(0.000000, 264.000000)">
              <g id="Twitter_Circle" transform="translate(48.000000, 0.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="CircleBG" fill="#5DA8DC" xlinkHref="#path-1"></use>
                <path
                  d="M16.2838571,13.5515678 C16.2838571,13.827206 16.3137143,14.0948241 16.3733571,14.3523819 L16.3733571,14.3523819 C13.4867857,14.2054874 10.9281429,12.8070352 9.21414286,10.6770653 L9.21414286,10.6770653 C8.91507143,11.1982312 8.74421429,11.8029045 8.74421429,12.4468342 L8.74421429,12.4468342 C8.74421429,13.6662412 9.35728571,14.7427638 10.2892857,15.373608 L10.2892857,15.373608 C9.72092857,15.3565126 9.18435714,15.196603 8.71535714,14.9349648 L8.71535714,14.9349648 L8.71535714,14.9782312 C8.71535714,16.6826432 9.91271429,18.1041709 11.5025,18.4261709 L11.5025,18.4261709 C11.2113571,18.5086935 10.9042857,18.5499196 10.5873571,18.5499196 L10.5873571,18.5499196 C10.3637857,18.5499196 10.1451429,18.5288141 9.93357143,18.4875879 L9.93357143,18.4875879 C10.3757857,19.884 11.6575,20.9012161 13.1777857,20.9283719 L13.1777857,20.9283719 C11.9894286,21.8721407 10.491,22.4345327 8.86442857,22.4345327 L8.86442857,22.4345327 C8.58414286,22.4345327 8.307,22.4184925 8.03571429,22.3862714 L8.03571429,22.3862714 C9.57285714,23.3822412 11.3992143,23.9648241 13.3596429,23.9648241 L13.3596429,23.9648241 C19.7487143,23.9648241 23.2412857,18.6072563 23.2412857,13.9601005 L23.2412857,13.9601005 C23.2412857,13.8070854 23.2383571,13.6542111 23.2323571,13.5042211 L23.2323571,13.5042211 C23.9110714,13.0082412 24.5002857,12.3894975 24.9642857,11.6842211 L24.9642857,11.6842211 C24.3422857,11.9638693 23.6725714,12.1530452 22.9700714,12.2375377 L22.9700714,12.2375377 C23.6874286,11.8029045 24.2379286,11.1137387 24.4972857,10.2927337 L24.4972857,10.2927337 C23.8256429,10.6951457 23.0833571,10.9879497 22.2904286,11.1449749 L22.2904286,11.1449749 C21.6585,10.4617889 20.7552143,10.0351759 19.7556429,10.0351759 L19.7556429,10.0351759 C17.8389286,10.0351759 16.2838571,11.6097889 16.2838571,13.5515678 L16.2838571,13.5515678 Z"
                  id="TwitterIcon"
                  fill="#FFFFFF"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const PinterestCircle = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Pinterest Circle</title>
    <g
      id="112019---Share-Updates-After-Meeting"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SocialShare_Desktop_1280"
        transform="translate(-853.000000, -408.000000)"
        fill="#CD252C"
      >
        <g id="RECIPE_OVERVIEW" transform="translate(12.000000, 124.000000)">
          <g
            id="RecipeOverview_Text"
            transform="translate(745.000000, 20.000000)"
          >
            <g id="SocialIcons" transform="translate(0.000000, 264.000000)">
              <g
                id="Pinterest_Circle"
                transform="translate(96.000000, 0.000000)"
              >
                <path
                  d="M16,0 C7.16352263,0 0,7.16339095 0,15.9998683 C0,22.7783374 4.21728395,28.5729712 10.169284,30.9042305 C10.0293004,29.638321 9.90301235,27.6914568 10.224856,26.3095309 C10.5154897,25.0607407 12.1010041,18.3564115 12.1010041,18.3564115 C12.1010041,18.3564115 11.622321,17.3979918 11.622321,15.9809053 C11.622321,13.7561811 12.9118025,12.0952099 14.517465,12.0952099 C15.8824033,12.0952099 16.541893,13.1201317 16.541893,14.349037 C16.541893,15.7218765 15.6678848,17.7742222 15.2167243,19.6761811 C14.8397037,21.2688066 16.0154074,22.5676379 17.5860412,22.5676379 C20.4298272,22.5676379 22.6157037,19.5689877 22.6157037,15.2406914 C22.6157037,11.4099095 19.8630453,8.73125926 15.9324444,8.73125926 C11.3800165,8.73125926 8.70781893,12.1459095 8.70781893,15.6748642 C8.70781893,17.0500741 9.23746502,18.5245761 9.89853498,19.3261564 C10.0291687,19.4845761 10.0483951,19.6235062 10.0095473,19.7850864 C9.888,20.2906337 9.61817284,21.3771852 9.56510288,21.5996049 C9.49530864,21.8926091 9.33320165,21.9546337 9.03005761,21.8135967 C7.03157202,20.883358 5.78238683,17.9618765 5.78238683,15.6152099 C5.78238683,10.5679012 9.44934979,5.93290535 16.354107,5.93290535 C21.9044609,5.93290535 26.2178765,9.888 26.2178765,15.1737942 C26.2178765,20.6879342 22.7409383,25.1256626 17.9151276,25.1256626 C16.2939259,25.1256626 14.7697778,24.2833909 14.2480329,23.2884938 C14.2480329,23.2884938 13.4457942,26.3433745 13.2512922,27.0920165 C12.8899424,28.4818436 11.91493,30.2238025 11.2626831,31.2865185 C12.7598354,31.7499259 14.3506173,32 16,32 C24.8366091,32 32,24.8366091 32,15.9998683 C32,7.16339095 24.8366091,0 16,0"
                  id="PinterestIcon"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
const EmailCircle = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Email Circle</title>
    <defs>
      <path
        d="M0,16 C0,24.8365614 7.1634386,32 16,32 L16,32 C24.8365614,32 32,24.8365614 32,16 L32,16 C32,7.1634386 24.8365614,0 16,0 L16,0 C7.1634386,0 0,7.1634386 0,16 L0,16 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="112019---Share-Updates-After-Meeting"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SocialShare_Desktop_1280"
        transform="translate(-901.000000, -408.000000)"
      >
        <g id="RECIPE_OVERVIEW" transform="translate(12.000000, 124.000000)">
          <g
            id="RecipeOverview_Text"
            transform="translate(745.000000, 20.000000)"
          >
            <g id="SocialIcons" transform="translate(0.000000, 264.000000)">
              <g id="Email_Circle" transform="translate(144.000000, 0.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="CircleBG" fill="#404040" xlinkHref="#path-1"></use>
                <path
                  d="M8,10 L16,16 L24,10 L8,10 Z M7,11 L7,22 L25,22 L25,11 L16.4115854,17.9040408 C16.1706268,18.0966767 15.8293732,18.0966767 15.5884146,17.9040408 L7,11 Z"
                  id="EmailIcon"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
