import React, { useRef, useEffect, useState } from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react'
import styled from 'styled-components'
import '../../node_modules/video-react/dist/video-react.css'
import { mediaMaxWidth } from './styles/media'

const StyledPlayer = styled(Player)`
  .control-bar,
  .video-react,
  .video-react-play-progress:after {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: 300;
  }
  /* !important necessary to override default video styling... ridiculous */
  > .video-react-big-play-button {
    background: rgba(256, 256, 256, 0.5) !important;
    border: none !important;
    border-radius: 50% !important;
    color: #ffffff !important;
    left: 50% !important;
    margin: 0 !important;
    height: 100px !important;
    width: 100px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    transition: height 0s !important;
    transition: width 0s !important;
    ${mediaMaxWidth('tablet')`
      height: calc(23% * 1.75) !important;
      width: 23% !important;
    `}

    /* Arrow symbol */
    &:before {
      position: absolute !important;
      font-size: 100px !important;
      height: 100% !important;
      line-height: 0 !important;
      left: 50% !important;
      top: 50% !important;
      transform: translateX(-50%) !important;
      ${mediaMaxWidth('tablet')`
        font-size: calc(20vw) !important;
      `}
    }
  }
`

const Video = ({ src, poster }) => {
  const [subscribed, setSubscribed] = useState(false)
  const [playerState, setPlayerState] = useState({
    ended: false,
  })
  const playerRef = useRef()

  useEffect(() => {
    if (!subscribed) {
      playerRef.current.subscribeToStateChange(setPlayerState)
      setSubscribed(true)
    }
    if (playerState.ended) {
      playerRef.current.load()
    }
  }, [playerState.ended])
  
  return (
    <StyledPlayer
      src={src.replace('http:', 'https:').concat('#t=0.1')}
      playsInline={true}
      poster={poster && poster}
      ref={playerRef}
    >
      <BigPlayButton position="center" className="big-play-button-custom" />
      <ControlBar autoHide={false} className="control-bar" />
    </StyledPlayer>
  )
}

export default Video
