import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { DiscussionEmbed } from 'disqus-react'

import { mediaMaxWidth } from '../components/styles/media'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import SettingsContext from '../context/SettingsContext'
import RecipeOverviewMedia from '../components/OverviewMedia'
import RecipeOverviewText from '../components/OverviewText'
import RecipeDetailsBar from '../components/DetailsBar'
import RecipeIngredients from '../components/Ingredients'
import RecipeEquipment from '../components/Equipment'
import RecipeTasks from '../components/Tasks'
import EmailCatpure from '../components/EmailCapture'

const RecipePageLayout = styled(Layout)`
  --max-col: 1fr;
  --min-col: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-template-areas:
    'media  media  media  media  media  media  media  text   text   text   text   text'
    'detail detail detail detail detail detail detail detail detail detail detail detail'
    'aside  aside  aside  aside  ...... tasks  tasks  tasks  tasks  tasks  tasks  tasks'
    'aside  aside  aside  aside  ...... tasks  tasks  tasks  tasks  tasks  tasks  tasks'
    'email  email  email  email  email  email  email  email  email  email  email  email'
    'comments comments comments comments comments comments comments comments comments comments comments comments'
    'footer footer footer footer footer footer footer footer footer footer footer footer';
    max-width: 1160px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 108px;

  ${mediaMaxWidth('tablet')`
    --max-col: 42px;
    --min-col: 1fr;
    grid-template-areas:
      'media  media  media  media  media  media  media  media  media  media  media  media'
      'text   text   text   text   text   text   text   text   text   text   text   text'
      'detail detail detail detail detail detail detail detail detail detail detail detail'
      'aside  aside  aside  aside  aside  aside  aside  aside  aside  aside  aside  aside'
      'tasks  tasks  tasks  tasks  tasks  tasks  tasks  tasks  tasks  tasks  tasks  tasks'
      'email  email  email  email  email  email  email  email  email  email  email  email'
      'comments comments comments comments comments comments comments comments comments comments comments comments'
      'footer footer footer footer footer footer footer footer footer footer footer footer';
    grid-template-columns: auto;
    margin-top: 106px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    > *:not(.recipe-overview-media):not(.recipe-details-bar):not(header):not(footer) {
      padding-left: 20px;
      padding-right: 20px;
    }
    > aside {
      justify-content: space-between;
      display: flex;
      margin-bottom: 60px;
    }
  `}

  ${mediaMaxWidth('mobile')`
    margin-top: 92px;
    > aside {
      flex-flow: column nowrap;
      margin-bottom: 32px;
    }
  `}
  ${mediaMaxWidth('mobileSmall')`
    > *:not(.recipe-overview-media):not(.recipe-details-bar):not(header) {
      padding-left: 5px;
      padding-right: 5px;
    }
  `}
`

export default function Recipe(props) {
  const recipe = props.data.contentfulRecipe
  const siteTitle = props.data.site.siteMetadata.title

  const { units, toggleUnits } = useContext(SettingsContext)

  const ingredientsByHeader =
    recipe.ingredients &&
    recipe.ingredients.reduce((acc, ingredient) => {
      if (!acc[ingredient.headerName]) {
        acc = { ...acc, [ingredient.headerName]: [ingredient] }
      } else {
        acc = {
          ...acc,
          [ingredient.headerName]: [...acc[ingredient.headerName], ingredient],
        }
      }
      return acc
    }, {})

  const dietaryRestrictions = recipe.categories.reduce((list, category) => {
    if (category.mainCategory === 'Dietary Restriction')
      list.push(category.subCategory)
    return list
  }, [])

  const tasksByStep =
    recipe.tasks &&
    recipe.tasks.reduce((acc, task) => {
      if (!acc[task.taskStep]) {
        acc = { ...acc, [task.taskStep]: [task] }
      } else {
        acc = { ...acc, [task.taskStep]: [...acc[task.taskStep], task] }
      }
      return acc
    }, {})
  // To remove "Finishing Touches section & add to end of "Cook" section
  if (tasksByStep) {
    tasksByStep['COOK'] = [...tasksByStep['COOK'], ...tasksByStep['FINISH']]
    delete tasksByStep['FINISH']
  }

  const mediaUrl =
    recipe.contentfulMedia &&
    recipe.contentfulMedia.file &&
    recipe.contentfulMedia.file.url
      ? recipe.contentfulMedia.file.url
      : recipe.mediaUrl

  // Disqus comments
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: recipe.slug, title: recipe.title },
  }

  return (
    <>
      <RecipePageLayout location={props.location} customSEO>
        <SEO
          recipe={recipe}
          title={`${recipe.title} | Hestan Cue`}
          description={recipe.synopsis.synopsis}
          image={recipe.mediaUrl}
          pathname={`/recipes/${recipe.slug}`}
          author="Hestan Smart Cooking"
        />
        <Helmet>
          <title>{recipe.title}</title>
        </Helmet>

        {mediaUrl && (
          <RecipeOverviewMedia
            gridArea="media"
            mediaUrl={mediaUrl}
            overviewVideo={recipe.overviewVideo}
          />
        )}

        <RecipeOverviewText
          gridArea="text"
          title={recipe.title}
          synopsis={recipe.synopsis.synopsis}
          dietaryRestrictions={dietaryRestrictions}
          sauceTitle={recipe.sauceTitle}
          sauceSynopsis={recipe.sauceSynopsis}
          mediaUrl={recipe.mediaUrl}
          url={props.location.href}
        />

        <RecipeDetailsBar
          gridArea="detail"
          servings={recipe.servings}
          prepTimeMinutes={
            recipe.prepTimeMinutes + recipe.inactivePrepTimeMinutes
          }
          cookTimeMinutes={recipe.cookTimeMinutes}
        />

        <aside style={{ gridArea: 'aside' }}>
          {ingredientsByHeader && (
            <RecipeIngredients ingredientsByHeader={ingredientsByHeader} />
          )}
          <RecipeEquipment equipment={recipe.equipment} />
          <EmailCatpure area="aside" />
        </aside>

        {tasksByStep && (
          <RecipeTasks tasksByStep={tasksByStep} gridArea="tasks" />
        )}
        <EmailCatpure area="bottom" gridArea="email" />
        <div style={{ gridArea: 'comments', marginTop: '2em' }}>
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </RecipePageLayout>
    </>
  )
}

export const pageQuery = graphql`
  query RecipeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulRecipe(slug: { eq: $slug }) {
      slug
      mediaUrl
      overviewVideo
      title
      servings
      sauceTitle
      # contentfulMedia {
      #   file {
      #     url
      #   }
      # }
      sauceSynopsis {
        sauceSynopsis
      }
      synopsis {
        synopsis
      }
      categories {
        mainCategory
        subCategory
      }
      ingredients {
        name
        headerName
        treatment
        ordinalQuantity
        metricQuantity
        metricUnit
        standardUnit
        standardQuantity
      }
      inactivePrepTimeMinutes
      cookTimeMinutes
      prepTimeMinutes
      equipment {
        name
        quantity
      }
      tasks {
        id
        ingredients {
          metricQuantity {
            en_US
          }
          metricUnit {
            en_US
          }
          name {
            en_US
          }
          ordinalQuantity {
            en_US
          }
          standardQuantity {
            en_US
          }
          standardUnit {
            en_US
          }
          treatment {
            en_US
          }
        }
        mediaAutoplay
        mediaRepeat
        mediaType
        mediaUrl
        prompt {
          prompt
          childMarkdownRemark {
            html
          }
        }
        taskStep
        taskType
        temperatureInC
        temperatureInF
        timeInSeconds
        title
      }
    }
  }
`
