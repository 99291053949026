import styled from 'styled-components'
import { mediaMaxWidth } from './media'

export default styled.div`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* margin-bottom: 16px; */

  padding-bottom: 12px;
  .title {
    text-transform: uppercase;
    /* white-space: nowrap; */
  }
  .toggle {
    display: flex;
    flex-flow: row nowrap;
    font-size: 14px;
    span {
      color: #f47521;
      font-weight: 400;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &.expand {
      > span {
        border: 1px solid #f47521;
        border-radius: 16px;
        line-height: 1;
        padding: 7px 16px;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
        ${mediaMaxWidth('mobileSmall')`
          margin-left: 2px;
          padding: 7px 10px;
        `}
      }
    }
  }
  ${mediaMaxWidth('mobileSmall')`
      align-items: center;
    `}
`
