import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { kebabCase } from 'lodash'
import { mediaMaxWidth } from './styles/media'
import SocialShareIcon from './SocialShareIcon'

const OverviewContainer = styled.div`
  grid-area: ${props => props.gridArea};
  margin-left: 9px;
  margin-top: 20px;
  > .title {
    margin-bottom: 16px;
  }
  > p.p1 {
    margin-bottom: 24px;
  }
  > .dietary-restrictions {
    --row-gap: 12px;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: calc(
      var(--row-gap) * -1
    ); /* for 12px gap when row wraps & no margin on last row */
    > .dietary-restriction {
      border-left: 1px solid #cbcfd1;
      border-right: 1px solid #cbcfd1;
      color: #757575;
      font-size: 14px;
      font-weight: 300;
      height: 28px;
      line-height: 28px;
      margin-bottom: var(--row-gap);
      margin-left: -1px; /* keeps 1px side borders from doubling up */
      padding: 0px 12px;
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        color: #f47521;
      }
    }
  }

  > .social-icons {
    margin-top: 24px;
    display: flex;
    height: 32px;
    > .social-icon {
      & + * {
        margin-left: 16px;
      }
      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }
    }
  }

  ${mediaMaxWidth('tablet')`
    margin: 0;
    text-align: center;
    > .title {
        margin-bottom: 12px
    }
    > p.p1 {
      margin-bottom: 20px;
    }
    > .dietary-restrictions {
      justify-content: center;
    }
    > .social-icons {
      justify-content: center;
      margin-top: 20px;
    }
  `}
  ${mediaMaxWidth('mobile')`
    div.title {
      > .sauce-title {
        font-size: 16px;
        line-height: 20px;
      }
    }
  `}
`

const RecipeOverviewText = ({
  title,
  synopsis,
  dietaryRestrictions,
  gridArea,
  sauceTitle,
  sauceSynopsis,
  mediaUrl,
  url,
}) => (
  <OverviewContainer className="recipe-overview-text" gridArea={gridArea}>
    <div className="title">
      <h2 className="main-title">
        {sauceTitle ? `${title} with ${sauceTitle}` : title}
      </h2>
    </div>
    <p className="p1">{synopsis}</p>
    <div className="dietary-restrictions">
      {dietaryRestrictions.map((restriction, i) => (
        <Link
          to={`/${kebabCase(restriction)}/`}
          className="dietary-restriction"
          key={i}
        >
          {restriction}
        </Link>
      ))}
    </div>
    <SocialIcons
      recipeName={title}
      synopsis={synopsis}
      mediaUrl={mediaUrl}
      url={url}
    />
  </OverviewContainer>
)

export default RecipeOverviewText

const SocialIcons = ({ recipeName, synopsis, mediaUrl, url }) => {
  return (
    <div className="social-icons">
      <SocialShareIcon type="facebook" url={url} />
      <SocialShareIcon
        type="twitter"
        url={url}
        shareText={`The best ${recipeName} I’ve ever had from @HestanCue #cookedoncue`}
      />
      <SocialShareIcon
        type="pinterest"
        url={url}
        shareText={synopsis}
        mediaUrl={mediaUrl}
      />
      <SocialShareIcon
        type="email"
        subject={`The best ${recipeName} from Hestan Cue`}
        shareText={`Don’t miss out on the tastiest ${recipeName} from Hestan Cue! Let’s cook it tonight! 
${url}`}
        url={url}
      />
    </div>
  )
}
