import React from 'react'
import styled from 'styled-components'
import { mediaMaxWidth } from './styles/media'

const EquipmentContainer = styled.div`
  > div.title {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 12px;
    > h3 {
      text-transform: uppercase;
    }
  }
  div.equipment-list {
    padding: 0;
    > h4 {
      color: #404040;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 12px;
    }
    > ul {
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      > li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  ${mediaMaxWidth('tablet')`
    flex: 1;
    margin-left: 20px;
  `}
  ${mediaMaxWidth('mobile')`
    margin-left: 0;
  `}
`
const RecipeEquipment = ({ equipment }) => {
  return (
    <EquipmentContainer className="recipe-equipment">
      <div className="title">
        <h3>Equipment</h3>
      </div>
      <div className="equipment-list">
        <ul>
          {equipment.map((item, i) => {
            const { name, quantity } = item
            return (
              <li key={i}>
                <span>{name}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </EquipmentContainer>
  )
}

export default RecipeEquipment
