import React from 'react'
import styled from 'styled-components'
import Video from './Video'
import { mediaMaxWidth } from './styles/media'

const MediaContainer = styled.div`
  grid-area: ${props => props.gridArea};
  margin-bottom: 28px;
  max-height: 400px;
  img {
    object-fit: contain;
    max-height: 400px;
    width: 100%;
  }
  ${mediaMaxWidth('mobile')`
    margin-bottom: 16px;
  `}
`

const RecipeOverviewMedia = ({ mediaUrl, overviewVideo, gridArea }) => {
  return (
    <MediaContainer className="recipe-overview-media" gridArea={gridArea}>
      {overviewVideo ? (
        <Video
          src={overviewVideo.replace('http:', 'https:')}
          poster={mediaUrl.replace('http:', 'https:')}
        />
      ) : (
        <img src={mediaUrl.replace('http:', 'https:')} />
      )}
    </MediaContainer>
  )
}

export default RecipeOverviewMedia
