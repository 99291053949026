import React from 'react'
import styled from 'styled-components'
import { mediaMaxWidth } from './styles/media'

const BarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  grid-area: ${props => props.gridArea};
  height: 92px;
  justify-content: space-between;
  left: 0;
  margin-bottom: 60px;
  position: relative;
  right: 0;
  &:before {
    background: rgba(241, 241, 241, 0.5);
    bottom: 0;
    content: '';
    /* viewport-width + content - gutter */
    /* -100vw + ( contentwidth + (100vw - contentwidth)/2 ) */
    left: calc(-100vw + (1120px + ((100vw - 1120px) / 2)));
    position: absolute;
    right: calc(-100vw + (1120px + ((100vw - 1120px) / 2)));
    top: 0;
    z-index: -1;
    @media (max-width: 1120px) {
      left: 0;
      right: 0;
    }
  }
  > div {
    align-items: center;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: center;
  }
  ${mediaMaxWidth('tablet')`
    background: rgba(241, 241, 241, 0.5);
    margin-top: 32px;
    &:before {
      display: none;
    }
  `}
  ${mediaMaxWidth('mobile')`
    flex-flow: column nowrap;
    height: auto;
    margin-bottom: 32px;
    margin-top: 28px;
    padding: 28px 0;
    > div + * {
      margin-top: 28px;
    }
  `}
`

const RecipeDetailsBar = ({
  prepTimeMinutes,
  cookTimeMinutes,
  servings,
  gridArea,
}) => (
  <BarContainer className="recipe-details-bar" gridArea={gridArea}>
    <div>
      <h3>{servings}</h3>
      <span>Servings</span>
    </div>
    <div>
      <h3>{prepTimeMinutes} Mins</h3>
      <span>Prep Time</span>
    </div>
    <div>
      <h3>{cookTimeMinutes} Mins</h3>
      <span>Cook Time</span>
    </div>
  </BarContainer>
)

export default RecipeDetailsBar
